import React, { useEffect, useState } from 'react';
import { SupabaseFunctions } from '../supaBaseClient';

const ProductTable = ({ products }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [dollarFloat, setDollarFloat] = useState(0);

  useEffect(() => {
    GetTotalPrice(products);
  }, [products]);

  const handleSelectProduct = (product) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(product)
        ? prevSelected.filter((p) => p !== product)
        : [...prevSelected, product]
    );
  };

  const handleSellItems = () => {
    if (selectedProducts.length === 0) {
      window.alert("Please select some items to sell");
      return;
    }

    console.log('Selected Products:', selectedProducts);
    selectedProducts.forEach((product) => {
      SupabaseFunctions.MarkProductResell(product.id);
    });

    window.open('https://www.usekuai.com/itemsforsale', '_blank', 'noopener,noreferrer');

  };

  const extractDollarValue = (text) => {
    if (!text) {
      return 0; // Return 0 if text is null or undefined
    }
    const regex = /\$([0-9,.]+)/;
    const match = text.match(regex);

    if (match) {
      const dollarValue = match[1];
      return parseFloat(dollarValue);
    } else {
      console.log("No dollar value found");
      return 0;
    }
  };

  const GetTotalPrice = (products) => {
    const total = products.reduce((acc, product) => acc + extractDollarValue(product.est_price), 0);
    setDollarFloat(total);
  };

  return (
    <div className="overflow-x-auto">
      {/* Button and Total Value Container */}
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-extrabold text-black">${dollarFloat.toFixed(2)}</p>
          <p className="text-gray-600 text-sm">Total Closet Value</p>
        </div>
        <button
          onClick={handleSellItems}
          className="bg-purple-500 text-white py-2.5 px-6 rounded-md shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-300"
        >
          Sell Items
        </button>
      </div>

      {/* Table */}
      <div className="rounded-lg border border-gray-300 bg-white shadow-sm">
        <table className="w-full rounded-lg overflow-hidden">
          <thead>
            <tr>
              <th className="py-3 px-4 text-left text-black font-semibold border-b"></th>
              <th className="py-3 px-4 text-left text-black font-semibold border-b">Image</th>
              <th className="py-3 px-4 text-left text-black font-semibold border-b">Brand</th>
              <th className="py-3 px-4 text-left text-black font-semibold border-b">Product</th>
              <th className="py-3 px-4 text-left text-black font-semibold border-b">Estimated Resale Value</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, i) => {
              // product.image_url = product.image_url || product.image_url_code
              const isSelected = selectedProducts.includes(product);
              return (
                <tr
                  // key={product.id}
                  key={i}
                  className={`cursor-pointer ${
                    isSelected
                      ? 'border-2 border-purple-600 bg-purple-50 rounded-lg'
                      : 'hover:bg-purple-50'
                  }`}
                  onClick={() => handleSelectProduct(product)}
                >
                  <td className="py-3 px-4 border-b rounded-lg">
                    {isSelected && (
                      <div className="w-6 h-6 bg-purple-600 text-white rounded-full flex items-center justify-center">
                        ✔
                      </div>
                    )}
                  </td>
                  <td className="py-3 px-4 border-b rounded-lg">
                    <img
                      src={product.image_url}
                      alt={product.name}
                      className="w-32 h-32 object-cover rounded-lg"
                    />
                  </td>
                  <td className="py-3 px-4 border-b rounded-lg">{product.sender}</td>
                  <td className="py-3 px-4 border-b rounded-lg">{product.product_name}</td>
                  <td className="py-3 px-4 border-b rounded-lg">{product.est_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductTable;