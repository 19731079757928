import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://jspsxdrujasijbuysskc.supabase.co";
const supabaseKey ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpzcHN4ZHJ1amFzaWpidXlzc2tjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY2ODk5MTIsImV4cCI6MjA0MjI2NTkxMn0.Drny54MMiQDGmfE7NwNYsMgQizryiNqNwshSWSpCKQc";

export const supabase = createClient(supabaseUrl, supabaseKey);

const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toISOString().split('T')[0];
};

export const SupabaseFunctions = {
    
    GetUser: async (email) => {
        const { data, error } = await supabase
            .from('Users')
            .select()
            .eq('email', email);
        
        if (error) {
            console.error('Error retrieving user:', error);
        } else if (!data || data.length === 0) {
            console.warn('No user found with the provided email.');
        } else {
            const userId = data[0].id;
            localStorage.setItem('userId', userId);
            console.log('User ID stored in localStorage:', userId);
        }
    },

    CreateUser: async (email) => {
        const { data: insertData, error: insertError } = await supabase
            .from('Users')
            .insert([{ email }], { returning: 'minimal' }); // No data expected here
    
        console.log('CreateUser insert response:', { insertData, insertError });
    
        if (insertError) {
            console.error('Error storing email:', insertError);
            return;
        }
    
        // Fetch the inserted user based on email
        const { data: selectData, error: selectError } = await supabase
            .from('Users')
            .select()
            .eq('email', email);
    
        console.log('CreateUser select response:', { selectData, selectError });
    
        if (selectError || !selectData || selectData.length === 0) {
            console.warn('User could not be fetched after insert.');
        } else {
            const userId = selectData[0].id;
            localStorage.setItem('userId', userId);
            console.log('User ID stored in localStorage:', userId);
        }
    },
    
    CreateProducts: async (products) => {
        const userId = localStorage.getItem('userId');
    
        const productsToInsert = products.map(product => ({
            product_name: product.name,
            quantity: Math.floor(parseInt(product.quantity)),
            purchase_date: formatDate(product.date),
            image_url: product.image_url,
            image_urls: product.image_urls,
            price: product.price,
            size: product.size,
            sender: product.sender,
            user_id: userId,
            est_price: product.est_price
        }));
    
        const { data, error } = await supabase
            .from('Products')
            .insert(productsToInsert);
    
        if (error) {
            console.log('Data being inserted:', productsToInsert);
            console.error('Error storing products:', error);
        } else {
            console.log('Products stored successfully:', data);
        }
    },

    GetProducts: async () => {
        const userId = localStorage.getItem('userId');
        const { data, error } = await supabase
            .from('Products')
            .select()
            .eq('user_id', userId);
    
        if (error) {
            console.error('Error retrieving products:', error);
        } else {
            console.log('Products retrieved successfully:', data);
            return data
        }
    },


    MarkProductResell: async (productId) => {
        const userId = localStorage.getItem('userId');
        const { data, error } = await supabase
            .from('Products')
            .update({ wants_to_resell: true})
            .eq('user_id', userId)
            .eq('id', productId)
    
        if (error) {
            console.error('Error retrieving products:', error);
        } else {
            console.log('Products retrieved successfully:', data);
            return data
        }
    },


    GetProductFetchDate: async () => {
        const userId = localStorage.getItem('userId');
        const { data, error } = await supabase
        .from('Users')
        .select('updated_at')
        .eq('id', userId)
        .single(); 

        if (error) {
            console.error('Error getting product last date:', error);
        }
        return data ? data.updated_at : null;
    },

    UpdateProductFetchDate: async () => {
        const userId = localStorage.getItem('userId');
        const { data, error } = await supabase
            .from('Users')
            .update({ updated_at: new Date().toISOString() })
            .eq('id', userId)
    
        if (error) {
            console.error('Error updating product last date:', error);
        }
    },
    
};

