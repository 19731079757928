// src/App.js

import React from "react";
import GmailReader from "./gmailReader";

const App = () => {
  return (
    <div className="App">
      <GmailReader />
    </div>
  );
};

export default App;